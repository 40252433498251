// COMPONENTS
import Base from "./base";
import Default from "./default";

export default function Container({ children, blackOut, noFooter }) {
  return (
    <Base blackOut={blackOut} noFooter={noFooter}>
      <Default>{children}</Default>
    </Base>
  );
}
