import React from "react";
import Link from "next/link";

//COMPONENTS
import Base from "../components/container";

export default function FourOhFour() {
  return (
    <main
      className="min-h-full bg-cover bg-top sm:bg-top"
      style={{
        backgroundImage:
          'url("https://images.unsplash.com/photo-1530840197133-665af68f9d71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80")',
      }}
    >
      <Base>
        <div className="min-h-screen mx-auto max-w-7xl px-4 py-16 text-center ">
          <p className="text-base font-semibold text-black text-opacity-50">
            404
          </p>
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-softWhite sm:text-5xl">
            Uh oh! I think you&lsquo;re lost.
          </h1>
          <p className="mt-2 text-lg font-medium text-softWhite text-opacity-50">
            It looks like the page you&lsquo;re looking for doesn&lsquo;t exist.
          </p>
          <div className="mt-6 flex flex-row justify-center items-center">
            <div className="grid gap-8 items-start justify-center lg:justify-start">
              <div className="relative group">
                <div className="absolute -inset-1 bg-gradient-to-r from-pink-600 to-purple-600 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
                <Link href="/">
                  <button className="relative px-7 py-4 bg-softWhite rounded-lg leading-none flex items-center divide-x divide-gray-600">
                    <span className="text-black group-hover:colorGradient pb-[0.09rem] transition duration-200 font-semibold">
                      Go Back Home &rarr;
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Base>
    </main>
  );
}
